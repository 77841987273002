._3YUuVzLLIWsgKCibrfxMH4 {
  display: flex;
  align-items: center;
  font-weight: bolder;
/*color #37474F;*/
}
._3YUuVzLLIWsgKCibrfxMH4 i {
  width: 25px;
}
._3YUuVzLLIWsgKCibrfxMH4 span {
  text-transform: uppercase;
}
.wdnAJV8tBSIZvyTMj426d {
  color: #cfd8dc;
}
._2Lp11cOyhoAADqnj3p_W5 {
  height: 15px !important;
}
._2anLn0SaCmmkpNKKzDA1F3 {
  cursor: pointer;
  overflow: visible !important;
}
._1BPQqsg24TASztB0sq_jZv {
  color: rgba(242,60,24,0.8) !important;
  justify-content: center !important;
  cursor: pointer;
  min-width: 40px !important;
}
._22yZtxH1LbKfv4Phq9Ls49 {
  background: #e6e9eb;
  color: #77909d !important;
}
._22yZtxH1LbKfv4Phq9Ls49 .wdnAJV8tBSIZvyTMj426d {
  color: rgba(118,144,158,0.369);
}
._2SXIcqSjDYRIfIEpDVLdIS {
  padding: 0 0 0 5px !important;
}
._2anLn0SaCmmkpNKKzDA1F3 span,
._2f6DTPgptfnERidbtcayES .cEO_tVlgJbK6-EjkRMIH9 {
  min-width: 20px;
  min-height: 17px;
  cursor: pointer;
  border-radius: 20px;
}
._3kjN_i8-LGL20eR_lSA4ZA:hover ._2anLn0SaCmmkpNKKzDA1F3 span,
._2f6DTPgptfnERidbtcayES:hover .cEO_tVlgJbK6-EjkRMIH9 {
  background: #fff;
  border: 1px solid;
  padding: 5px;
}
