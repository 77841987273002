._3c_AYtPb7aZ7VjDF4wvgvF {
  min-height: 70px;
  width: 70px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 6px;
  margin: 0 2.5px;
}
._2jnndkNGfhNn16QN0768rV {
  color: #fff;
  font-size: 15px;
  text-align: center;
  height: 70px;
  border: 1px solid #263238;
  border-radius: 3px;
  padding: 15px 0;
  box-sizing: border-box;
}
.A5hNeGnPPHAn5Vhihsgjs {
  position: relative;
  margin: 0;
}
._1EjiepEPkuRIGOo_h1yjBc {
  background-color: #263238;
  border-radius: 15px;
  color: #fff;
  padding: 10px;
  padding-left: 40px;
  height: 40px;
  box-sizing: border-box;
  width: 100px;
  border: 1px solid #1a2226;
}
._2IJmkZc0n10qc6jlOX3VuI {
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 100%;
  height: 20px;
  width: 20px;
  border: 1px solid #1a2226;
}
._3DFtvDwY-VUTZP93EoUjrv {
  padding: 15px 30px;
  background: #263238;
  bottom: 93px;
  width: 255px;
  box-sizing: border-box;
  position: absolute;
  color: #fff;
  border-radius: 5px;
  border: 1px solid #1a2226;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
._3DFtvDwY-VUTZP93EoUjrv > * {
  margin: 5px 0;
}
._1fHt-WS0gs4lIVkZLlNkk3 {
  width: 100%;
  margin: 0;
  padding: 5px;
  background-image: linear-gradient(to right, #000, #fff);
  border-radius: 15px;
}
._1fHt-WS0gs4lIVkZLlNkk3::-webkit-slider-thumb {
  border: 2px solid #fff;
  background-color: #000;
  border-radius: 15px;
}
._1fHt-WS0gs4lIVkZLlNkk3::-ms-track {
  background: transparent;
  opacity: 1;
}
._17igWv4kwhIuutRL7aM5L3 {
  display: flex;
}
._1kt_SRO1z8cUZvFzxCTCzm {
  height: 36px;
  width: auto;
  border-radius: 50px;
  padding: 5px 10px;
  display: inline-flex;
  align-items: center;
  color: #b0bec5;
  font-size: 13px;
  border: 1px solid #263238 !important;
  background: #263238;
  border: 1px solid #1a2226;
}
._1kt_SRO1z8cUZvFzxCTCzm * {
  vertical-align: middle;
}
._3LTsWcJyjKWTHR5FHWhyi2 {
  background-color: transparent !important;
}
