.hotel-directory + .vModalDefaultFooter .rbCheckboxOptionButton,
.hotel-directory .rbCheckboxOptionButton {
  border: none !important;
  color: #313c42;
  display: flex;
  font-size: 12px;
}
.hotel-directory + .vModalDefaultFooter .rbCheckboxOptionButton:hover,
.hotel-directory .rbCheckboxOptionButton:hover {
  border: none;
}
.hotel-directory + .vModalDefaultFooter .rbCheckboxOptionButton .material-icons.rbCheckboxOptionIcon,
.hotel-directory .rbCheckboxOptionButton .material-icons.rbCheckboxOptionIcon {
  font-size: 20px;
  color: #90a4ae;
}
.hotel-directory + .vModalDefaultFooter .rbCheckboxOptionButton .rbCheckboxOptionText,
.hotel-directory .rbCheckboxOptionButton .rbCheckboxOptionText {
  vertical-align: top;
  padding-left: 10px;
  color: #90a4ae;
  text-transform: none;
  font-size: 14px;
}
.hotel-directory + .vModalDefaultFooter .rbCheckboxOptionButton.selected .material-icons.rbCheckboxOptionIcon,
.hotel-directory .rbCheckboxOptionButton.selected .material-icons.rbCheckboxOptionIcon {
  color: #00b8ab;
}
.hotel-directory + .vModalDefaultFooter .rbCheckboxOptionButton.selected .rbCheckboxOptionText,
.hotel-directory .rbCheckboxOptionButton.selected .rbCheckboxOptionText {
  vertical-align: top;
  padding-left: 10px;
  color: #37474f;
}
.hotel-directory + .vModalDefaultFooter .rbCheckboxOptionButton.dark,
.hotel-directory .rbCheckboxOptionButton.dark {
  color: #727d84;
}
.hotel-directory + .vModalDefaultFooter .rbCheckboxOptionButton.dark.selected,
.hotel-directory .rbCheckboxOptionButton.dark.selected {
  color: #00b8ab;
}
.hotel-directory + .vModalDefaultFooter .rbCheckboxOptionButton.dark.selected .rbCheckboxOptionText,
.hotel-directory .rbCheckboxOptionButton.dark.selected .rbCheckboxOptionText {
  color: #fff;
}
.hotel-directory + .vModalDefaultFooter .rbCheckboxOptionButton.dark-icon.selected .material-icons.rbCheckboxOptionIcon,
.hotel-directory .rbCheckboxOptionButton.dark-icon.selected .material-icons.rbCheckboxOptionIcon {
  color: #37474f;
}
.hotel-directory .rbInputContainer {
  position: relative;
}
.hotel-directory .rbInputContainer .icon {
  position: absolute;
  top: 9px;
  left: 15px;
  color: #90a4ae;
  z-index: 1;
}
.hotel-directory .rbInputContainer .rbSelectWrapper.simple {
  background-color: transparent;
}
.hotel-directory .rbInputContainer .clear-icon {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 8px;
  color: #90a4ae;
}
.hotel-directory .rbInputContainer.dark .rbInputLabel * {
  background: #313c42;
  white-space: nowrap;
}
.hotel-directory .rbInputContainer.dark .rbInput,
.hotel-directory .rbInputContainer.dark .rbSelect {
  background: transparent !important;
  border-color: #263238 !important;
}
.hotel-directory .rbInputContainer .rbInput,
.hotel-directory .rbInputContainer .rbSelect {
  border-radius: 20px;
  padding: 0 15px;
  outline: none;
  color: #90a4ae;
}
.hotel-directory .rbInputContainer .rbInput:focus,
.hotel-directory .rbInputContainer .rbSelect:focus {
  border: 1px solid #00a99d;
  color: #546e7a;
}
.hotel-directory .rbInputContainer .icon + .rbInput,
.hotel-directory .rbInputContainer .icon + .rbSelectWrapper .rbSelect {
  padding-left: 40px !important;
}
.hotel-directory .rbInputContainer.rbInputInvalid .rbInputError {
  margin: 0 11px;
  color: #f15a25;
  font-weight: normal;
  background: transparent;
}
.hotel-directory .rbInputContainer.rbInputInvalid .icon {
  color: #f15a25;
}
.hotel-directory .rbInputContainer.rbInputInvalid .rbInput,
.hotel-directory .rbInputContainer.rbInputInvalid .rbSelect {
  border-color: #f15125 !important;
  background-color: #fff !important;
}
.hotel-directory .rbInputContainer.rbInputInvalid .rbInputLabel {
  color: #b0bec5 !important;
}
.hotel-directory .rbInputContainer .rbInputLabel {
  position: absolute;
  top: -6px;
  width: calc(100% - 40px);
  margin: -2px 15px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  transition: all 0.3s;
}
.hotel-directory .rbInputContainer .rbInputLabel * {
  padding: 0 5px;
  background-color: #fff;
}
.hotel-directory .rbInputContainer:focus-within .icon {
  color: #00a99d;
}
.hotel-directory .rbInputContainer:focus-within .rbInputLabel {
  color: #00a99d;
  z-index: 99;
}
.hotel-directory .rbInputContainer.rbInputEmpty:not(:focus-within) .rbInput,
.hotel-directory .rbInputContainer.rbInputEmpty:not(:focus-within) .rbSelect {
  background-color: #eceff1;
  border-color: #eceff1;
}
.hotel-directory .rbInputContainer.rbInputEmpty:not(:focus-within) .rbInputLabel {
  top: 11px;
  font-size: 12px;
  width: auto;
}
.hotel-directory .rbInputContainer.rbInputEmpty:not(:focus-within) .rbInputLabel * {
  background: transparent;
  padding: 0;
}
.hotel-directory .rbInputContainer.rbInputEmpty:not(:focus-within) .rbInputLabel .rbInputLabelHint {
  position: relative;
  padding-left: 5px;
}
.hotel-directory .rbInputContainer:not(.rbInputEmpty) .rbInputLabel {
  z-index: 99;
}
.hotel-directory + .vModalDefaultFooter .rbButton {
  border-radius: 20px;
}
.hotel-directory .bar {
  min-height: 88px;
  background: #313c42;
  display: flex;
  padding: 25px 20px;
  box-sizing: border-box;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 3px solid #263238;
}
.hotel-directory .bar.no-border {
  border-bottom: none;
}
.hotel-directory .bar.responsive .button.responsive {
  width: 36px;
  overflow: hidden;
  padding: 0 12px;
}
.hotel-directory .bar.responsive .title {
  display: none;
}
.hotel-directory .manage-table {
  border-bottom: 5px solid #dedede;
}
.hotel-directory .manage-table .grouper {
  color: #78909c;
  min-height: 30px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: sticky;
  top: 46px;
  z-index: 9;
  background: #e6e9eb;
  font-weight: 600;
  padding: 10px 7px;
  border-top: 5px solid #dedede;
  font-size: 12px;
  border-bottom: 1px solid #cfd8dc !important;
}
.hotel-directory .manage-table .grouper .hotel-type {
  height: 15px;
  overflow: hidden;
}
.hotel-directory .manage-table .grouper .check-box {
  cursor: pointer;
  margin-right: 10px;
}
.hotel-directory .manage-table .row {
  display: flex;
  align-items: center;
  overflow: visible;
  height: 45px;
  position: relative;
  color: #37474f;
  border-bottom: 1px solid #cfd8dc !important;
}
.hotel-directory .manage-table .row.selected {
  background: #fff;
}
.hotel-directory .manage-table .row:hover {
  background: #eceff1;
}
.hotel-directory .manage-table .row.tableHead {
  background: #313c41;
  font-size: 12px;
  color: #acbec5;
  position: sticky;
  top: 0;
  z-index: 99;
}
.hotel-directory .manage-table .row .cell {
  font-size: 12px;
  box-sizing: border-box;
  flex: 1;
  width: unset;
  min-width: unset;
  border: 0;
  padding: 0;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: inherit;
  overflow: visible;
}
.hotel-directory .manage-table .row .cell.hidden {
  display: none !important;
}
.hotel-directory .manage-table .row .cell.cell-xs {
  min-width: 120px;
  max-width: 120px;
}
.hotel-directory .manage-table .row .cell.cell-s {
  min-width: 165px;
  max-width: 165px;
}
.hotel-directory .manage-table .row .cell.cell-l {
  min-width: 220px;
  max-width: 220px;
}
.hotel-directory .manage-table .row .cell.cell-xl {
  min-width: 270px;
  max-width: 270px;
}
.hotel-directory .manage-table .row .cell.head {
  height: 45px;
  vertical-align: bottom;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.hotel-directory .manage-table .row .cell.head.checkboxHead {
  max-width: 40px;
  min-width: 40px;
  padding: 0 8px;
}
.hotel-directory .manage-table .row .cell.checkboxCell {
  max-width: 40px;
  min-width: 40px;
  padding: 0 7px;
  min-height: 35px;
}
.hotel-directory .manage-table .row .cell.checkboxCell > span {
  font-size: 25px !important;
}
.hotel-directory .manage-table .row .cell.checkboxCell.plus i {
  background: #77909d;
  color: #fff;
  border-radius: 3px;
  cursor: pointer;
}
.hotel-directory .manage-table .row .cell + .hotel-directory .manage-table .row .cell {
  margin-left: 5px;
}
.hotel-directory .manage-table .wrapped-cells {
  background-color: rgba(218,224,226,0.58);
  color: #627b85;
  font-size: 12px;
  transform: scaleY(0);
  padding: 0 30px;
  justify-content: space-between;
  overflow: hidden;
  transition: all 0.1s ease-out;
  height: 0;
  transform-origin: top;
}
.hotel-directory .manage-table .wrapped-cells.open {
  transform: scaleY(1);
  border-bottom: 5px solid #dedede;
  height: auto;
}
.hotel-directory .manage-table .wrapped-cells .wrapped-cell {
  min-height: 25px;
  display: flex;
  border-bottom: 1px solid #eceff1;
  padding: 2px 10px;
  align-items: center;
}
.hotel-directory .manage-table .wrapped-cells .wrapped-cell .value {
  overflow: hidden;
  white-space: normal;
}
.hotel-directory .manage-table .wrapped-cells .wrapped-cell .label {
  width: 250px;
}
.hotel-directory .button {
  height: 36px;
  display: inline-flex;
  padding: 0 15px;
  border-radius: 36px;
  white-space: nowrap;
  background-image: linear-gradient(to top, #f5f6f7 10%, #fff 11.05%, #fff 100%);
  border: 1px solid #dcdee0;
  color: #546e7a;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
}
.hotel-directory .button:hover {
  background-image: linear-gradient(to top, #f5f6f7 10%, #fff 11.05%, #fff 100%);
  border: 1px solid #dcdee0;
  color: #455a64;
}
.hotel-directory .button.disabled,
.hotel-directory .button:disabled {
  background-image: unset;
  background-color: #eceff1;
  border: 1px solid #cfd8dc;
  color: #78909c;
}
.hotel-directory .button.cta {
  background-image: unset;
  background-color: #00b8ab;
  border: 1px solid #00a99d;
  color: #fff;
}
.hotel-directory .button.cta:hover {
  background-color: #00a99d;
}
.hotel-directory .button.cta:disabled,
.hotel-directory .button.cta.disabled {
  background-color: #eceff1;
  border: 1px solid #cfd8dc;
  color: #b0bec5;
}
.hotel-directory .button i.material-icons {
  margin-left: -5px;
  margin-right: 10px;
}
.hotel-directory .button i.material-icons:last-child:not(:first-child) {
  margin: 0;
  margin-left: 10px;
}
.hotel-directory .button.icon-only {
  width: 36px;
  padding: 5px 5px 5px 0;
  justify-content: center;
}
.hotel-directory .button.icon-only i.material-icons {
  margin: 0;
  padding: 5px 0 0 5px;
}
.hotel-directory .button.dark {
  background-image: unset;
  background-color: #263238;
  border: 1px solid #263238;
  color: #b0bec5;
}
.hotel-directory .button.dark:hover {
  color: #fff;
}
.hotel-directory .button.dark.disabled,
.hotel-directory .button.dark:disabled {
  background-color: #3c464c;
  border: 1px solid #3c464c;
  color: #637078;
}
.hotel-directory .button.dark.cta {
  background-image: unset;
  background-color: #00a99d;
  border: 1px solid #263238;
  color: #fff;
}
.hotel-directory .button.dark.cta:hover {
  background-color: #00b8ab;
  border: 1px solid #00b8ab;
}
.hotel-directory .button.dark.cta:disabled,
.hotel-directory .button.dark.cta.disabled {
  background-color: #3c464c;
  border: 1px solid #3c464c;
  color: #637078;
}
.hotel-directory .button+.hotel-directory .button {
  margin-left: 5px;
}
.hotel-directory .dropdown {
/*position absolute;*/
  min-width: 160px;
  right: 0;
  top: 3px;
  border-radius: 3px;
  padding: 5px 0;
  border: 1px solid #b0bec5;
  margin: 5px 0;
  list-style-type: none;
  max-height: 300px;
  overflow: auto;
  background: #fff;
}
.hotel-directory .dropdown .item {
  min-height: 33px;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px 40px 5px 15px;
  box-sizing: border-box;
  cursor: pointer;
  white-space: nowrap;
  align-items: center;
  font-size: 15px;
  color: #546e7a;
}
.hotel-directory .dropdown .item .icon {
  color: #90a4ae;
  margin-left: -5px;
  margin-right: 10px;
}
.hotel-directory .dropdown .item .icon2 {
  position: absolute;
  right: 10px;
}
.hotel-directory .dropdown .item:hover {
  color: #546e7a;
  background: #eceff1;
}
.hotel-directory .dropdown .item:hover .icon {
  color: #546e7a;
}
.hotel-directory .dropdown .item.active {
  color: #37474f;
  background: #eceff1;
}
.hotel-directory .dropdown .item.disabled {
  color: #546e7a;
  opacity: 0.3;
}
.hotel-directory .dropdown.dark {
  border: 1px solid #1a2226;
  background-color: #263238;
}
.hotel-directory .dropdown.dark .item {
  color: #90a4ae;
}
.hotel-directory .dropdown.dark .item .icon {
  color: #b0bec5;
}
.hotel-directory .dropdown.dark .item:hover {
  color: #90a4ae;
  background: #313c42;
}
.hotel-directory .dropdown.dark .item:hover .icon {
  color: #fff;
}
.hotel-directory .dropdown.dark .item.active {
  color: #fff;
  background: #313c42;
}
.hotel-directory .dropdown.dark .item.disabled {
  color: #90a4ae;
}
.hotel-directory i.material-icons {
  font-size: 20px;
  width: fit-content;
  height: fit-content;
}
.vModal .vModalDefaultFooter {
  padding: 0 15px;
}
.vModal .vModalDefaultHeader {
  padding-left: 15px;
}
.vModal .vModalDefaultBody {
  margin-top: 15px;
  margin-bottom: 0;
  padding: 0 15px;
}
